exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aligned-js": () => import("./../../../src/pages/aligned.js" /* webpackChunkName: "component---src-pages-aligned-js" */),
  "component---src-pages-good-practices-js": () => import("./../../../src/pages/good-practices.js" /* webpackChunkName: "component---src-pages-good-practices-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-demands-js": () => import("./../../../src/pages/our-demands.js" /* webpackChunkName: "component---src-pages-our-demands-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-the-pledge-js": () => import("./../../../src/pages/the-pledge.js" /* webpackChunkName: "component---src-pages-the-pledge-js" */),
  "component---src-pages-why-disclose-js": () => import("./../../../src/pages/why-disclose.js" /* webpackChunkName: "component---src-pages-why-disclose-js" */)
}

